.List {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin: 10px 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: 1px solid #424242;
    padding: 10px 15px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.Empty {
  height: 26px;
}

.GuessText {
  flex: 0 1 auto;
  color: white;
}

.Hints {
  flex: 0 1 auto;
}