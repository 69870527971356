import { useSelector } from 'react-redux';
import * as classes from './Thumbnail.module.scss';

const thumbnailOrder = [3, 1, 2];

export const Thumbnail = () => {
  const id = useSelector((state) => state.solution.solution.id);
  const guessCount = useSelector((state) => state.puzzle.guesses.length);

  const thumbnails = [];
  for (let i = 0; i <= Math.min(thumbnailOrder.length - 1, guessCount); i++) {
    const url = `https://img.youtube.com/vi/${id}/${thumbnailOrder[i]}.jpg`;  

    thumbnails.push(
      <li
        key={i}
        className={classes.Item}>
        <img
          className={classes.Thumbnail}
          src={url}
          alt={`Thumbnail #${i + 1}`} />
      </li>
    );
  }

  const filler = [];
  for (let i = guessCount; i < thumbnailOrder.length - 1; i++) {
    filler.push(
      <li
        key={`filler-${i}`}
        className={classes.Item}>
        <div className={classes.Filler}>
          <div className={classes.FillerText}>?</div>
        </div>
      </li>

    );
  }

  return (
    <ul className={classes.List}>
      { thumbnails }
      { filler }
    </ul>
  );
}
