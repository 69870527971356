import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from './DatePicker';
import { puzzleActions } from '../stores/puzzle';
import * as classes from './Guess.module.scss';



export const Guess = () => {
  const dispatch = useDispatch();

  const { solution, isGameOver } = useSelector((state) => ({
    isGameOver: state.puzzle.isGameOver,
    solution: new Date(state.solution.solution.date)
  }));

  const [ date, setDate ] = useState(new Date());

  if (isGameOver) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // check if guess is correct
    const guessYear = date.getFullYear();
    const guessMonth = date.getMonth();
    const guessDay = date.getDate();

    const solutionYear = solution.getUTCFullYear();
    const solutionMonth = solution.getUTCMonth();
    const solutionDay = solution.getUTCDate();

    const correctYear = solutionYear - guessYear;
    const correctMonth = solutionMonth - guessMonth;
    const correctDay = solutionDay - guessDay;

    if (!correctYear && !correctMonth && !correctDay) {
      dispatch(puzzleActions.setSolved({
        date: date.toISOString(),
        hint: [correctYear, correctMonth, correctDay]
      }));
    } else {
      dispatch(puzzleActions.addGuess({
        date: date.toISOString(),
        hint: [correctYear, correctMonth, correctDay]
      }));
    }
  };


  const handleDateChange = (dateValue) => {
    setDate(dateValue);
  }

  let guessPreview = [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0')
  ].join('-');

  return (
    <div
      className={classes.Container}>

      <div className={classes.DatePicker}>
        <DatePicker
          value={date}
          onChange={handleDateChange} />
      </div>

      <div className={classes.RightPanel}>
        <div className={classes.Preview}>
          { guessPreview }
        </div>
        <button
          className={classes.Submit}
          onClick={handleSubmit}
          disabled={isGameOver}>
          Submit
        </button>
      </div>
    </div>
  );
}