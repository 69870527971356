.List {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;

  overflow-x: auto;
  max-width: 620px;
}

.Item {
  flex: 0 0 200px;

}

.Filler {
  border: 1px solid #424242;
  height: 150px;
  width: 200px;
  box-sizing: border-box;
  background-color: #424242;

  display: flex;
  justify-content: center;
  align-items: center;
  
  .FillerText {
    flex: 1 1 auto;
    text-align: center;
    font-size: 64px;
    color: #9E9E9E;
  }

}

.Thumbnail {
  min-width: 200px;
}