import { Thumbnail } from './components/Thumbnail';
import { Title } from './components/Title';
import { History } from './components/History';
import { Guess } from './components/Guess';
import './App.scss';

export const App = () => {
  return (
    <div className="App">
      <div className="Thumbnail">
        <Thumbnail />
      </div>
      <div className="Title">
        <Title />
      </div>
      <div className="History">
        <History />
      </div>
      <div className="Guess">
        <Guess />
      </div>
    </div>
  );
};
