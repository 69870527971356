import { useState, useEffect, useRef } from 'react';

import flatpickr from 'flatpickr';
import 'flatpickr/dist/themes/dark.css';

const months = [];
for (let i = 0; i < 12; i++) {
  months.push({
    value: i,
    label: new Date(2022, i, 1, 0, 0, 0).toLocaleDateString(undefined, {
      timeZone: 'UTC',
      month: 'long'
    })
  });
}

export const DatePicker = ({ value, onChange }) => {
  const dateRef = useRef(null);
  const dateContainerRef = useRef(null);

  useEffect(() => {
    const el = dateContainerRef.current;
    if (!el) {
      return;
    }

    dateRef.current = new flatpickr(el, {
      minDate: new Date(2020, 0, 1, 0, 0, 0),
      maxDate: new Date(),
      inline: true,
      onChange: (dates) => {
        onChange(dates[0])
      }
    });
    
  }, [dateContainerRef]);

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.setDate(value, false);
    }
  }, [value, dateRef]);

  return (
    <div ref={dateContainerRef} />
  );
}