import { useSelector } from 'react-redux';
import * as classes from './History.module.scss';

export const History = () => {
  const puzzle = useSelector((state) => state.puzzle);

  const items = [];

  for (let idx = 0; idx < 5; idx++) {
    if (idx >= puzzle.guesses.length) {
      items.push(
        <li key={idx}>
          <div className={classes.Empty} />
        </li>
      );
      continue;
    }

    const guess = puzzle.guesses[idx];
    const hint = puzzle.hints[idx];

    const date = new Date(guess);
    const guessYear = date.getFullYear();
    const guessMonth = String(date.getMonth() + 1).padStart(2, '0');
    const guessDay = String(date.getDate()).padStart(2, '0');

    const hintIcons = [];
    for (const hintValue of hint) {
      if (!hintValue) {
        hintIcons.push('✅');
        continue;
      }

      if (hintValue < 0) {
        hintIcons.push('⬇️');
      }
      else {
        hintIcons.push('⬆️');
      }
    }

    items.push(
      <li key={idx}>
        <div className={classes.GuessText}>
          {`${guessYear}-${guessMonth}-${guessDay}`}
        </div>
        <div className={classes.Hints}>
          {hintIcons.join(' ')}
        </div>
      </li>
    );
  }

  return (
    <ul className={classes.List}>
      { items }
    </ul>
  );
}