import { useSelector } from 'react-redux';
import * as classes from './Title.module.scss';

export const Title = () => {
  const { title, date, id } = useSelector((state) => ({
    title: state.solution.solution.title,
    date: new Date(state.solution.solution.date),
    id: state.solution.solution.id,
  }));

  const { guesses, isGameOver } = useSelector((state) => ({
    isGameOver: state.puzzle.isGameOver,
    guesses: state.puzzle.guesses.length
  }));

  let content = '';
  if (isGameOver) {
    content = title;
  }
  else if (guesses === 0) {
    content = '';
  }
  else {
    content = title.substring(0, guesses);
  }

  const dateStr = [
    date.getUTCFullYear(),
    String(date.getUTCMonth() + 1).padStart(2, '0'),
    String(date.getUTCDate()).padStart(2, '0')
  ].join('-');

  const dateClass = [classes.Date];
  if (isGameOver) {
    dateClass.push(classes.DateVisible)
  }

  const youtube = `https://www.youtube.com/watch?v=${id}`;

  let titleElement = (
    <div className={classes.Title}>
      { content }
    </div>
  );

  if (isGameOver) {
    titleElement = (
      <a
        href={youtube}
        target="_blank"
        className={classes.TitleLink}>
          { content }
      </a>
    );
  }

  return (
    <div className={classes.Container}>
      { titleElement }
      <div className={dateClass.join(' ')}>
        { dateStr }
      </div>
    </div>
  );
};
