import {
  configureStore
} from '@reduxjs/toolkit';

import { solutionSlice } from './solution';
import { puzzleSlice } from './puzzle';

export const store = configureStore({
  reducer: {
    solution: solutionSlice.reducer,
    puzzle: puzzleSlice.reducer
  }
});