.Container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;

  @media only screen and (max-width: 519px) {
    display: block;
  }
}

.DatePicker {
  flex: 0 0 auto;

  @media only screen and (max-width: 519px) {
    width: 310px;
    margin: 0 auto;
  }
}

.RightPanel {
  flex: 0 0 150px;

  @media only screen and (max-width: 519px) {
    margin-top: 20px;
  }
}

.Preview {
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

.Submit {
  width: 100%;
  padding: 20px;
  cursor: pointer;

  color: white;
  font-weight: bold;
  background-color: #424242;
  border: 1px solid #757575;

  &:hover, &:active {
    background-color: #616161;
  }
}