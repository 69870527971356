import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSolved: false,
  isGameOver: false,
  guesses: [],
  hints: []
};

export const puzzleSlice = createSlice({
  name: 'puzzle',
  initialState,
  reducers: {
    addGuess(state, action) {
      const data = action.payload;
      state.guesses = [...state.guesses, data.date];
      state.hints = [...state.hints, data.hint];

      state.isGameOver = state.isSolved || state.guesses.length >= 5;
    },
    setSolved(state, action) {
      const data = action.payload;
      state.guesses = [...state.guesses, data.date];
      state.hints = [...state.hints, data.hint];
      state.isSolved = true;
      state.isGameOver = true;
    }
  }
});

export const puzzleActions = puzzleSlice.actions;