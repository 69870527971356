.Container {
  margin: 10px 0;
}

.Title {
  color: white;
  text-align: center;
  min-height: 1.5rem;
}

.TitleLink {
  display: block;
  text-decoration: none;
  color: #64B5F6;
  text-align: center;
  font-weight: bold;
  min-height: 1.5rem;

  &:hover, &:active {
    text-decoration: underline;
  }
}

.Date {
  color: white;
  text-align: center;
  display: none;
}

.DateVisible {
  display: block;
}