import { differenceInDays } from 'date-fns';
import { createSlice } from '@reduxjs/toolkit';
import solutionObj from '../../data/solutions.json';
const solutions = solutionObj.items;

const GAME_SEED = new Date(2022, 6, 1, 0, 0, 0, 0);
const solutionIndex = differenceInDays(new Date(), GAME_SEED) % (solutions.length - 1);

const initialState = {
  solutionIndex,
  solution: solutions[solutionIndex]
};

export const solutionSlice = createSlice({
  name: 'solution',
  initialState,
  reducers: {}
});
